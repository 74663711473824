import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Login from './pages/Login';
import ScanQRCode1 from './pages/ScanQrCode1';
import ScanQRCode2 from './pages/ScanQrCode2';
import Summary from './pages/Summary';
import Dashboard from './pages/DashBoard';
import { ToastProvider } from './contexts/ToastContext';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <AuthProvider>
      <ToastProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/scan1" element={<ProtectedRoute><ScanQRCode1 /></ProtectedRoute>} />
            <Route path="/scan2" element={<ProtectedRoute><ScanQRCode2 /></ProtectedRoute>} />
            <Route path="/summary" element={<ProtectedRoute><Summary /></ProtectedRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute adminOnly={true} seeInventory={true}><Dashboard /></ProtectedRoute>} />
          </Routes>
        </Router>
      </ToastProvider>
    </AuthProvider>
  );
}

export default App;