// src/components/ProtectedRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

function ProtectedRoute({ children, adminOnly, seeInventory = false }) {
    const { user, loading } = useContext(AuthContext);
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (!user) {
      return <Navigate to="/" />;
    }
  
    if (adminOnly && !user.isAdmin && seeInventory && !user.see_inventory) {
      return <Navigate to="/scan1" />;
    }

    return children;
  }

export default ProtectedRoute;