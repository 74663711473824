// src/contexts/ToastContext.js
import React, { createContext, useState } from 'react';

// Create the context
export const ToastContext = createContext();

// Create the provider component
export const ToastProvider = ({ children }) => {
  const [shouldShowToast, setShouldShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  return (
    <ToastContext.Provider value={{ shouldShowToast, setShouldShowToast, toastMessage, setToastMessage}}>
      {children}
    </ToastContext.Provider>
  );
};