import React, { useState, useEffect, useRef, useContext } from 'react';
import QrScanner from 'react-qr-scanner';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import { ToastContext } from '../contexts/ToastContext';
import { AuthContext } from '../contexts/AuthContext';
import Footer from '../components/Footer';


function ScanQRCode1() {
  const { logout } = useContext(AuthContext);
  const [error, setError] = useState('');
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [manualInput, setManualInput] = useState('');
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { shouldShowToast, setShouldShowToast, toastMessage} = useContext(ToastContext);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const checkPermissions = async () => {
      try {
        const permission = await navigator.permissions.query({ name: 'camera' });
        if (permission.state === 'granted') {
          setPermissionGranted(true);
        } else if (permission.state === 'prompt') {
          try {
            await navigator.mediaDevices.getUserMedia({ video: true });
            setPermissionGranted(true);
          } catch (err) {
            setPermissionGranted(false);
          }
        } else {
          setPermissionGranted(false);
        }
        permission.onchange = () => {
          setPermissionGranted(permission.state === 'granted');
        };
      } catch (err) {
        console.error('Failed to check permissions', err);
      }
    };

    checkPermissions();

    const userAgent = navigator.userAgent.toLowerCase();
    setIsMobile(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent));
  }, []);

  const handleLogout = async() => {
    try {
      await logout();
      // Redirect or show notification
      console.log("User signed out successfully");
      sessionStorage.clear();
  } catch (error) {
      console.error("Error signing out: ", error);
      // Handle error, e.g., show notification
  }
  };

  const handleScan = async (data) => {
      if (data) {
      const decodedText = data.text || 'QR code não contém texto';
      setError('');
  
      try {
          const response = await fetch('https://api.winglet.app/api/validate-equipment', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ qrCode1: decodedText }),
          });
          const result = await response.json();

          handleValidationResult(result, decodedText);
        }
       catch (err) {
        setError('Erro ao validar o QR code. Por favor, tente novamente.');
        console.error(err);
      }
    }
  };
  
  const handleValidationResult = (result, decodedText) => {
    setTimeout(() => {
      if (result.status === 'em uso') {
        navigate('/scan2', {
          state: {
            qrCode1: decodedText,
            action: 'Devolução',
            status: 'disponivel',
            type: result.type,
            manufacturer: result.manufacturer,
          },
        });
        console.log(result)
      }       else if (result.status === 'disponivel') {
        navigate('/scan2', {
          state: {
            qrCode1: decodedText,
            action: 'Entrega',
            status: 'em uso',
            type: result.type,
            manufacturer: result.manufacturer,
          },
        });
        console.log(result)
      }
      else if (result.status === 'quebrado' || result.status === 'inativo') {
        toast.error('Equipamento Quebrado / Inativo. Por favor, entregue outro equipamento', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      } else if (result.message === 'Equipamento não encontrado') {
        toast.error('Equipamento não encontrado. Verifique o código digitado.', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      } else {
        toast.error('Erro ao validar o equipamento. Por favor, tente novamente.', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }

    }, 500);
  };

  const handleError = (error) => {
    console.error(error);
    setError('Erro ao acessar a câmera. Verifique as permissões.');
  };

  const handleManualInput = (event) => {
    setManualInput(event.target.value);
  };

  const handleManualSubmit = async (event) => {
    event.preventDefault();
    
    try {
        const response = await fetch('https://api.winglet.app/api/validate-equipment', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ qrCode1: manualInput }),
        });
        const result = await response.json();
        handleValidationResult(result, manualInput);
      }
       catch (err) {
      setError('Erro ao validar o código do equipamento. Por favor, tente novamente.');
      console.error(err);
    }
  };

  const videoConstraints = {
    video: { facingMode: { exact: 'environment' } },
  };

  useEffect(() => {
    if (shouldShowToast) {
      toast.success(toastMessage, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      setShouldShowToast(false); // Reset the toast state
    }
  }, [shouldShowToast, setShouldShowToast, toastMessage]);

  const handleNavigateToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <div className="container d-flex flex-column align-items-center justify-content-center" style={{ height: '100vh', position: 'relative' }}>
      <button 
        className="btn btn-primary" 
        style={{ position: 'absolute', top: '10px', right: '90px' }}
        onClick={handleNavigateToDashboard}
      >
        Dashboard
      </button>
      <button 
        className="btn btn-danger" 
        style={{ position: 'absolute', top: '10px', right: '10px' }}
        onClick={handleLogout}
      >
        Logout
      </button>

      <div className="text-center mb-4">
        <h2>EQUIPAMENTO</h2>
        <h2>📱</h2>

      </div>

      <div className="card p-3 shadow-lg" style={{ width: '100%', maxWidth: '400px' }}>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        {isMobile ? (
          permissionGranted ? (
            <QrScanner
              delay={1000}
              onError={handleError}
              onScan={handleScan}
              style={{ width: '100%' }}
              constraints={videoConstraints}
            />
          ) : (
            <p>Permissão de câmera necessária. Por favor, permita o uso da câmera.</p>
          )
        ) : (
          <form onSubmit={handleManualSubmit}>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder='Código do Equipamento'
              id="manualInput"
              value={manualInput}
              onChange={handleManualInput}
              ref={inputRef}
            />
          </div>
          <button type="submit" className="btn btn-primary mt-3">Enviar</button>
        </form>
        )}
      </div>
      <p style={{fontSize: "12px"}} className="text-muted">Escaneie o QR Code do equipamento.</p>
      <Footer />
      <ToastContainer limit={1}/>
    </div>
  );
}

export default ScanQRCode1;