import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
  return (
    <footer className="footer text-center mt-4">
      <p>Ver. 0.1.1 - Beta - Depto. de Inovação e Novas Tecnologias</p>
    </footer>
  );
};

export default Footer;