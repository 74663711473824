// Login.js
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { ToastContext } from "../contexts/ToastContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";


function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const { setShouldShowToast, setToastMessage } = useContext(ToastContext);


  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    const toastId = toast.loading("Entrando...");
  
    if (!email || !password) {
      toast.error('Preencha todos os campos!', {
        position: 'bottom-right',
        autoClose: '3000',       
      })
      return;
    }
  
    try {
      await login(email, password);
      toast.update(toastId, {
        render: "Login realizado com sucesso!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
        position: 'bottom-right',
      }); // Ensure this line is correct
      navigate("/dashboard");
      setShouldShowToast(true);
      setToastMessage("Bem vindo de volta!");
    } catch (error) {
      console.log('ERRO', JSON.parse(error));
      if (JSON.parse(error.message) === "Authentication failed") {
        toast.update(toastId, {
          render: "Erro ao fazer o login. Verifique suas credenciais!",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
    }} finally {
      setLoading(false);

    }
  };

  return (
    <div
      className="container d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div
        className="card p-4 shadow-lg"
        style={{ width: "100%", maxWidth: "400px" }}
      >
        <div className="text-center mb-4">
          <img
            src="https://grupoorbital.nettdocs.com.br/storage/images/nettwork/9cGVZ9BckpArI0SpmXXy2vI2tbCZmWTqbFA5Spod.png"
            alt="Logo"
            className="img-fluid mb-2"
            style={{ maxHeight: "50px" }}
          />
          <p style={{ margin: "2rem" }}>Faça login para continuar</p>
        </div>

        <form onSubmit={handleLogin}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Digite seu email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Senha
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Digite sua senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="btn btn-success w-100"
            disabled={loading}
          >
            {loading ? "Carregando..." : "Login"}
          </button>
        </form>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default Login;