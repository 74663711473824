import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContext } from '../contexts/ToastContext';
import { AuthContext } from '../contexts/AuthContext';
import Footer from '../components/Footer';


function Summary() {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isBroken, setIsBroken] = useState(false);
  const [observations, setObservations] = useState('');
  const { setShouldShowToast, setToastMessage } = useContext(ToastContext);


  useEffect(() => {
  
    const { qrCode1, qrCode2, action, status, type, manufacturer } = location.state || {};
  
    if (!qrCode1 || !qrCode2 || !action || !status || !type || !manufacturer) {

      console.error('Dados inválidos, redirecionando para o início do processo.');
      navigate('/scan1');
    }
  }, [location, navigate]);


  const handleLogout = async() => {
    try {
      await logout();
      // Redirect or show notification
      console.log("User signed out successfully");
      sessionStorage.clear();
  } catch (error) {
      console.error("Error signing out: ", error);
      // Handle error, e.g., show notification
  }
  };

  const handleConfirm = useCallback(async () => {
    setSaving(true);
    const { qrCode1, qrCode2, action: initialAction, status: initialStatus, nomeCompleto, funcao } = location.state;
    let action = initialAction;
    let status = initialStatus;

    if (isBroken) {
        status = 'quebrado';
    } else if (action === 'Devolução' && status === 'em uso') {
        status = 'disponivel';
        action = 'Entrega';
    } else if (status === 'disponivel' && action === 'Entrega') {
        status = 'em uso';
        action = 'Devolução';
    }

    if (!qrCode1 || !qrCode2 || !action || !status) {
        console.error('Dados inválidos na confirmação.');
        setSaving(false);
        return;
    }

    try {
        const response = await fetch('https://api.winglet.app/api/confirm-transaction', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                qrCode1,
                qrCode2,
                user: user.email,
                action,
                status,
                observations: isBroken ? observations : null,
                nomeCompleto,
                funcao,
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Erro na resposta da API:', errorData);
            setSaving(false);
            return;
        }

        const result = await response.json();
        setSaving(false);

        if (result.message === 'Transaction confirmed') {
            navigate('/scan1');
            setToastMessage(`${action} do equipamento ${qrCode1} efetuada com sucesso!`);
            setShouldShowToast(true);
        }
    } catch (error) {
        console.error('Erro ao confirmar a transação:', error);
        setSaving(false);
    }
}, [location.state, isBroken, observations, user, navigate, setShouldShowToast, setToastMessage]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleConfirm();
      } else if (event.key === 'Escape') {
        navigate('/scan1');
      }
    };
  
    document.addEventListener('keydown', handleKeyPress);
  
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleConfirm, navigate]);
  

  if (loading) {
    return <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div>Carregando...</div>
    </div>;
  }

  if (saving) {
    return <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div>Salvando os dados...</div>
    </div>;
  }

  return (
    <div className="container d-flex flex-column align-items-center justify-content-center" style={{ height: '100vh', position: 'relative' }}>
      <button 
        className="btn btn-danger" 
        style={{ position: 'absolute', top: '10px', right: '10px' }}
        onClick={handleLogout}
      >
        Logout
      </button>

      <div className="card p-4 shadow-lg" style={{ width: '100%', maxWidth: '500px' }}>
        <h2 className="text-center mb-4">Resumo da {location.state?.action}</h2>
        
        <ul className="list-group mb-4">
  <li className="list-group-item"><strong>Responsável:</strong> {user?.email}</li>
  <li className="list-group-item"><strong>Data e Hora:</strong> {new Date().toLocaleString()}</li>
  <li className="list-group-item"><strong>Equipamento:</strong> {location.state?.type} {location.state?.manufacturer} - {location.state?.qrCode1} </li>
  <li className="list-group-item"><strong>Entregue para/por:</strong> <strong style={{color: 'red'}}>{location.state?.nomeCompleto}</strong></li>
  <li className="list-group-item"><strong>Função:</strong> <strong style={{color: 'red'}}>{location.state?.funcao}</strong></li>
  <li className="list-group-item"><strong>Chapa: </strong> <strong style={{color: 'red'}}>{location.state?.qrCode2}</strong></li>
</ul>
        
        <div className="form-check mb-4">
          <input 
            className="form-check-input" 
            type="checkbox" 
            id="brokenCheck" 
            checked={isBroken} 
            onChange={(e) => setIsBroken(e.target.checked)} 
          />
          <label className="form-check-label" htmlFor="brokenCheck">
            Marcar como quebrado
          </label>
        </div>

        {isBroken && (
          <div className="mb-4">
            <label htmlFor="observations" className="form-label">Observações</label>
            <textarea 
              className="form-control" 
              id="observations" 
              rows="3" 
              value={observations} 
              onChange={(e) => setObservations(e.target.value)}
              placeholder="Descreva o problema com o equipamento..."
            ></textarea>
          </div>
        )}
        
        <div className="d-flex justify-content-between">
          <button className="btn btn-secondary" onClick={() => navigate('/scan1')}>Retornar</button>
          <button 
            className="btn btn-primary" 
            onClick={handleConfirm}
          >
            Confirmar
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Summary;