// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    // Check if there's a token in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      // Fetch user data from the server
      fetchUserData(token);
    } else {
      setLoading(false);
    }
  }, []);

  const checkTokenValidity = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setUser(null);
      return;
    }
    
    // Here you would typically decode the token and check its expiration
    // For now, we'll just check if it exists
    setUser({ isAuthenticated: true }); // Set a minimal user object
  };
  
  useEffect(() => {
    checkTokenValidity();
  }, []);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.winglet.app/api/user', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }
      const data = await response.json();
      setUser(data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const login = async (email, password) => {
    try {
      const response = await fetch('https://api.winglet.app/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        localStorage.setItem('token', data.token);
        setUser({ id: data.userId, email: data.email, isAdmin: data.isAdmin, name: data.name, company_role: data.company_role, profile_picture: data.profile_picture});
      } else {
        //console.log([{data}])
        throw new Error(JSON.stringify(data));
      }
    } catch (error) {
      throw error
            // You might want to set an error state here to display to the user
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  const updatePassword = async (email, newPassword, oldPassword) => {
    console.log('AuthContext', email, newPassword, oldPassword);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.winglet.app/api/updatepassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ email, oldPassword, newPassword }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        return data; // Password updated successfully
      } else {
        throw new Error(data.message || 'Failed to update password');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout, updatePassword }}>
      {children}
    </AuthContext.Provider>
  );
};