import React, { useState, useEffect, useRef, useContext } from 'react';
import QrScanner from 'react-qr-scanner';
import { useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthContext } from '../contexts/AuthContext';
import Footer from '../components/Footer';
import { toast } from 'react-toastify';

function ScanQRCode2() {
  const { logout } = useContext(AuthContext);
  const [scanResult, setScanResult] = useState('');
  const [error, setError] = useState('');
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [manualInput, setManualInput] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const checkPermissions = async () => {
      try {
        const permission = await navigator.permissions.query({ name: 'camera' });
        if (permission.state === 'granted') {
          setPermissionGranted(true);
        } else if (permission.state === 'prompt') {
          try {
            await navigator.mediaDevices.getUserMedia({ video: true });
            setPermissionGranted(true);
          } catch (err) {
            setPermissionGranted(false);
          }
        } else {
          setPermissionGranted(false);
        }
        permission.onchange = () => {
          setPermissionGranted(permission.state === 'granted');
        };
      } catch (err) {
        console.error('Failed to check permissions', err);
      }
    };

    checkPermissions();

    const userAgent = navigator.userAgent.toLowerCase();
    setIsMobile(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent));
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      // Redirect or show notification
      console.log("User signed out successfully");
      sessionStorage.clear();
    } catch (error) {
      console.error("Error signing out: ", error);
      // Handle error, e.g., show notification
    }
  };

  const handleScan = async (data) => {
    if (data) {
      const decodedText = data.text || 'QR code não contém texto';

      setScanResult(decodedText);
      setError('');
  
      const { nomeCompleto, funcao } = await fetchPersonData(decodedText);
  
      navigate('/summary', {
        state: {
          qrCode1: location.state?.qrCode1,
          qrCode2: decodedText,
          action: location.state?.action,
          status: location.state?.status,
          nomeCompleto,
          funcao,
          type: location.state?.type,
          manufacturer: location.state?.manufacturer,
        },
      });
    }
  };

  const handleManualInput = (event) => {
    let value = event.target.value;
    
    // Ensure the value is numeric and has at most 5 digits
    if (/^\d{0,5}$/.test(value)) {
        // Check if the input value has exactly 4 digits and doesn't already start with zero
        if (value.length === 4 && !value.startsWith('0')) {
            value = '0' + value; // Add leading zero for 4-digit numbers
        }
        
        // If the input has 5 digits and starts with a zero, remove the leading zero
        if (value.length === 5 && value.startsWith('0')) {
            value = value.slice(1);
        }

        setManualInput(value);
    } else {
        toast.error('O código do crachá deve ter no máximo 5 dígitos.', {
            position: 'bottom-right',
            autoClose: '3000',
        });
    }
};

  const handleManualSubmit = async (event) => {
    event.preventDefault();
    if (manualInput.trim() === '') {
      toast.warning('Por favor, insira o código do crachá.', {
        position: 'bottom-right',
        autoClose: '3000',
      })
      return;
    }
  
    const { nomeCompleto, funcao } = await fetchPersonData(manualInput);
  
    navigate('/summary', {
      state: {
        qrCode1: location.state?.qrCode1,
        qrCode2: manualInput,
        action: location.state?.action,
        status: location.state?.status,
        nomeCompleto,
        funcao,
        type: location.state?.type,
        manufacturer: location.state?.manufacturer,
      },
    });
  };

  const handleError = (error) => {
    console.error('Erro ao acessar a câmera:', error);
    setError('Não foi possível acessar a câmera. Por favor, verifique as permissões.');
  };

  const videoConstraints = {
    video: { facingMode: { exact: 'environment' } },
  };

  const cornerStyles = {
    position: 'absolute',
    width: '20px',
    height: '20px',
    backgroundColor: 'transparent',
    zIndex: 10,
  };
  
  const cornerLines = [
    { top: 50, left: 50, borderTop: '5px solid white', borderLeft: '5px solid white' },
    { top: 50, right: 50, borderTop: '5px solid white', borderRight: '5px solid white' },
    { bottom: 50, left: 50, borderBottom: '5px solid white', borderLeft: '5px solid white' },
    { bottom: 50, right: 50, borderBottom: '5px solid white', borderRight: '5px solid white' },
  ];

  const updateCornersOnScanSuccess = (success) => {
    return cornerLines.map((line, index) => (
      <div
        key={index}
        style={{
          ...cornerStyles,
          ...line,
          borderColor: success ? 'green' : 'white',
        }}
      />
    ));
  };
  
  const fetchPersonData = async (qrCode2) => {
    try {
      const employeeData = JSON.parse(sessionStorage.getItem(qrCode2));
      if (employeeData) {
        const { nomeCompleto, funcao } = employeeData;
        return { nomeCompleto, funcao };
      } else {
        const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
        const response = await fetch('https://api.winglet.app/api/person-info', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Include the token in the Authorization header
          },
          body: JSON.stringify({ qrCode2 })
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch person data');
        }
        const data = await response.json();
        sessionStorage.setItem(qrCode2, JSON.stringify(data));
  
        const { nomeCompleto, funcao } = data;
        return { nomeCompleto, funcao };
      }
    } catch (error) {
      console.error('Error fetching person data:', error);
      return { nomeCompleto: 'Nome não encontrado', funcao: 'Função não encontrada' };
    }
  };

  const handleNavigateToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <div className="container d-flex flex-column align-items-center justify-content-center" style={{ height: '100vh', position: 'relative' }}>
      <button 
        className="btn btn-primary" 
        style={{ position: 'absolute', top: '10px', right: '90px' }}
        onClick={handleNavigateToDashboard}
      >
        Dashboard
      </button>
      <button 
        className="btn btn-danger" 
        style={{ position: 'absolute', top: '10px', right: '10px' }}
        onClick={handleLogout}
      >
        Logout
      </button>

      <div className="text-center mb-4">
        <h2> CRACHÁ</h2>
        <h2>🪪</h2>
      </div>

      <div className="card p-3 shadow-lg" style={{ width: '100%', maxWidth: '400px' }}>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        {isMobile ? (
          permissionGranted ? (
            <div style={{ position: 'relative' }}>
              <QrScanner
                delay={1000}
                onError={handleError}
                onScan={(data) => {
                  handleScan(data);
                  updateCornersOnScanSuccess(!!data);
                }}
                style={{ width: '100%' }}
                constraints={videoConstraints}
              />
              {updateCornersOnScanSuccess(!!scanResult)}
            </div>
          ) : (
            <p>Permissão de câmera necessária. Por favor, permita o uso da câmera.</p>
          )
        ) : (
          <form onSubmit={handleManualSubmit}>
      <div className="form-group">
        <input
          type="text" // Change to text to handle leading zeros
          className="form-control"
          id="manualInput"
          value={manualInput}
          onChange={handleManualInput}
          ref={inputRef}
          pattern='\d*'
          maxLength={5}
          minLength={4}
          placeholder='Código do Crachá'
        />
      </div>
      <button type="submit" className="btn btn-primary mt-3">Enviar</button> 
    </form>
        )}
      </div>
      <p style={{fontSize: '12px'}} className="text-muted">Escaneie o QR Code do crachá </p>
      <Footer />
    </div>
  );
}

export default ScanQRCode2;