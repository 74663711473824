import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Table,
  Tabs,
  Tab,
  Button,
  Form,
  Modal,
  Pagination,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faExclamationTriangle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFileExcel,
  faSignOutAlt,
  faPlus,
  faFileArrowUp,
  faQrcode,
  faEdit,
  faPrint,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import { ToastContext } from "../contexts/ToastContext";
import { AuthContext } from "../contexts/AuthContext";
import { QRCodeCanvas } from "qrcode.react";

function Dashboard() {
  const { user, logout, updatePassword } = useContext(AuthContext);
  const { shouldShowToast, setShouldShowToast, toastMessage } =
    useContext(ToastContext);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);

  const [activeTab, setActiveTab] = useState("inUse");
  const [equipments, setEquipments] = useState([]);
  const [filteredEquipments, setFilteredEquipments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage] = useState(3000);
  const [showAddEquipmentModal, setShowAddEquipmentModal] = useState(false);
  const [showQRCodeModal, setShowQRCodeModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const fetchEquipments = useCallback(
    async (page) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token available");
        }
        const response = await fetch(
          `https://api.winglet.app/api/equipments?page=${page}&limit=${itemsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.equipments && Array.isArray(data.equipments)) {
          setEquipments((prevEquipments) => {
            const newEquipments = [...prevEquipments];
            newEquipments[page - 1] = data.equipments;
            return newEquipments;
          });
          setTotalItems(data.totalCount);
        } else {
          console.error(
            "A resposta da API não contém um array de equipamentos:",
            data
          );
          setEquipments([]);
          setTotalItems(0);
        }
      } catch (error) {
        console.error("Erro ao buscar equipamentos:", error);
        setEquipments([]);
        setTotalItems(0);
      }
    },
    [itemsPerPage]
  );

  const filterEquipments = useCallback(() => {
    let filtered = equipments[currentPage - 1] || [];
    if (searchTerm) {
      filtered = filtered.filter((eq) =>
        Object.values(eq).some(
          (value) =>
            value !== null &&
            value !== undefined &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
    switch (activeTab) {
      case "inUse":
        filtered = filtered.filter((eq) => eq.currentStatus === "em uso");
        break;
      case "available":
        filtered = filtered.filter((eq) => eq.currentStatus === "disponivel");
        break;
      case "broken":
        filtered = filtered.filter(
          (eq) =>
            eq.currentStatus === "quebrado" || eq.currentStatus === "inativo"
        );
        break;
      case "over12h":
        filtered = filtered.filter((eq) => {
          const lastUsed =
            eq.history && eq.history.length > 0
              ? new Date(eq.history[eq.history.length - 1].date)
              : null;
          return (
            lastUsed &&
            eq.currentStatus === "em uso" &&
            new Date() - lastUsed > 12 * 60 * 60 * 1000 &&
            new Date() - lastUsed < 24 * 60 * 60 * 1000
          );
        });
        break;
      case "over24h":
        filtered = filtered.filter((eq) => {
          const lastUsed =
            eq.history && eq.history.length > 0
              ? new Date(eq.history[eq.history.length - 1].date)
              : null;
          return (
            lastUsed &&
            eq.currentStatus === "em uso" &&
            new Date() - lastUsed >= 24 * 60 * 60 * 1000
          );
        });
        break;
      case "inventario":
        break;
      default:
        filtered = [];
    }
    setFilteredEquipments(filtered);
  }, [activeTab, equipments, searchTerm, currentPage]);

  useEffect(() => {
    if (shouldShowToast) {
      toast.success(toastMessage, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      setShouldShowToast(false); // Reset the toast state
    }
  }, [shouldShowToast, setShouldShowToast, toastMessage]);

  useEffect(() => {
    // Fetch initial data
    fetchEquipments(currentPage);

    // Set up WebSocket connection
    const ws = new WebSocket('ws://localhost:5000/');

    ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Message from server:', data);

      if (data.type === "equipmentStatusChange") {
        console.log("entendido")
        setEquipments((prevEquipments) => {
          const updatedEquipments = prevEquipments.map((pageEquipments) =>
            pageEquipments.map((equipment) =>
              equipment.equipmentId === data.equipmentId
                ? { ...equipment, currentStatus: data.newStatus }
                : equipment
            )
          );
          console.log('Updated Equipments:', updatedEquipments);
          return updatedEquipments;
        });
      }
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    // Clean up WebSocket connection when the component unmounts
    return () => {
      ws.close();
    };
  }, [currentPage, fetchEquipments]);

  useEffect(() => {
    filterEquipments();
  }, [activeTab, equipments, searchTerm, filterEquipments, currentPage]);

  useEffect(() => {
    // Set the initial sort configuration when the tab changes
    setSortConfig({ key: "status", direction: "descending" });
  }, [activeTab]);

  const tabColumns = {
    inUse: [
      { key: "status", label: "Status" },
      { key: "equipmentId", label: "ID" },
      { key: "type", label: "Equipamento" },
      { key: "date", label: "Entregue em" },
      { key: "nomeCompleto", label: "Em uso por" },
    ],
    available: [
      { key: "status", label: "Status" },
      { key: "equipmentId", label: "ID" },
      { key: "type", label: "Equipamento" },
      { key: "lastUsed", label: "Último uso em" },
      { key: "nomeCompleto", label: "Último uso por" },
    ],
    broken: [
      { key: "status", label: "Status" },
      { key: "equipmentId", label: "ID" },
      { key: "type", label: "Equipamento" },
      { key: "lastUsed", label: "Data da quebra" },
      { key: "nomeCompleto", label: "Último uso por" },
      { key: "changeStatus", label: "Mudar Status" },
    ],
    over12h: [
      { key: "status", label: "Status" },
      { key: "equipmentId", label: "ID" },
      { key: "type", label: "Equipamento" },
      { key: "inUseTime", label: "Tempo em uso" },
      { key: "nomeCompleto", label: "Em uso por" },
    ],
    over24h: [
      { key: "status", label: "Status" },
      { key: "equipmentId", label: "ID" },
      { key: "type", label: "Equipamento" },
      { key: "inUseTime", label: "Tempo em uso" },
      { key: "nomeCompleto", label: "Em uso por" },
    ],
    inventario: [
      { key: "status", label: "Status" },
      { key: "equipmentId", label: "ID" },
      { key: "type", label: "Equipamento" },
      { key: "lastUsed", label: "Último uso em" },
      { key: "nomeCompleto" || "", label: "Usado por" },
      { key: "actions", label: "Ações" },
    ],
  };

  const handleSort = (key) => {
    let direction = "descending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "descending"
    ) {
      direction = "ascending";
    }
    setSortConfig({ key, direction });
  };
  
  const sortedEquipments = React.useMemo(() => {
    let sortableItems = [...filteredEquipments];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        // Sort by status in descending order
        if (a.status < b.status) {
          return 1;
        }
        if (a.status > b.status) {
          return -1;
        }
        // If status is the same, sort by date in ascending order
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [filteredEquipments, sortConfig]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;
    setIsUploading(true);
    const formData = new FormData();
    const totalEquipamentosUpload = formData.entries().length;
    formData.append("file", selectedFile);
    try {
      await fetch("https://api.winglet.app/api/upload", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setSelectedFile(null);
      setShowUploadModal(false);
      fetchEquipments(currentPage);
      toast.success(`${totalEquipamentosUpload} equipamentos importados com sucesso!`, {
        position: "bottom-right",
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error('Ops! Algo deu errado na importação.', {
        position: "bottom-right",
        autoClose: 3000,
      });
    } finally {
      setIsUploading(false);
    }
  };

  const exportToExcel = async () => {
    const workbook = XLSX.utils.book_new();

    try {
      // Fetch all equipments from the API
      const response = await fetch(
        `https://api.winglet.app/api/equipments?limit=${totalItems}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();

      if (data.equipments && Array.isArray(data.equipments)) {
        const allEquipments = data.equipments;

        const excelData = allEquipments.map((eq) => {
          const lastHistorico =
            eq.history && eq.history.length > 0
              ? eq.history[eq.history.length - 1]
              : null;
          const ultimoColaborador = lastHistorico
            ? `${lastHistorico.qrCode2 || "undefined"} - ${
                lastHistorico.nomeCompleto || "undefined"
              } - ${lastHistorico.funcao || "undefined"}`
            : "Não identificado";

          // Check if 'Último colaborador' should show nothing
          const displayUltimoColaborador =
            ultimoColaborador === "undefined - undefined - undefined"
              ? ""
              : ultimoColaborador;

          return {
            ID: eq.equipmentId,
            Equipamento: `${eq.type} ${eq.manufacturer || ""}`,
            Modelo: eq.model || "Modelo não informado",
            Setor: eq.department || "Setor não informado",
            "Número de Série": eq.serialnumber,
            "Número de Patrimônio": eq.companynumber,
            Status: eq.currentStatus || "Status não disponível",
            "Último uso": lastHistorico
              ? new Date(lastHistorico.date).toLocaleString()
              : "-",
            "Último colaborador": displayUltimoColaborador,
          };
        });

        const worksheet = XLSX.utils.json_to_sheet(excelData);
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          "Inventário de Equipamentos"
        );
        XLSX.writeFile(
          workbook,
          `inventario_${new Date().toLocaleString()}.xlsx`
        );
      } else {
        console.error(
          "A resposta da API não contém um array de equipamentos:",
          data
        );
        alert(
          "Erro ao exportar dados. Verifique o console para mais detalhes."
        );
      }
    } catch (error) {
      console.error("Erro ao buscar equipamentos para exportação:", error);
      alert("Erro ao exportar dados. Verifique o console para mais detalhes.");
    }
  };

  const handleStatusChange = async (equipmentId) => {
    try {
      await fetch(
        `https://api.winglet.app/api/equipments/${equipmentId}/status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            status: "disponivel",
            user: user.email,
            action: "Devolução",
            observations: "Retornado de Quebra",
          }),
        }
      );
      // Refresh the equipment data after updating the status
      fetchEquipments(currentPage);
    } catch (error) {
      console.error("Erro ao atualizar o status:", error);
      // Handle the error appropriately (e.g., show an error message)
    }
  };

  const handleAddEquipment = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const equipmentData = Object.fromEntries(formData.entries());
    console.log(equipmentData.code);

    try {
      await fetch("https://api.winglet.app/api/equipmentsadd", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          code: equipmentData.code,
          type: equipmentData.type,
          model: equipmentData.model,
          manufacturer: equipmentData.manufacturer,
          serialnumber: equipmentData.serialnumber,
          companynumber: equipmentData.companynumber,
          user: user.email,
        }),
      });
      setShowAddEquipmentModal(false);
      toast.success('Equipamento adicionado com sucesso!', {
        position: "bottom-right",
        autoClose: 3000,
      })
      fetchEquipments(currentPage);
    } catch (error) {
      console.error("Error adding equipment:", error);
      toast.error('Ops! Algo deu errado na adição do equipamento.', {
        position: "bottom-right",
        autoClose: 3000,
      })

    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      // Redirect or show notification
      console.log("User signed out successfully");
      sessionStorage.clear();
    } catch (error) {
      console.error("Error signing out: ", error);
      // Handle error, e.g., show notification
    }
  };

  const handleBaixa = async (equipmentId) => {
    try {
      await fetch(
        `https://api.winglet.app/api/equipments/${equipmentId}/status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            user: user.email,
            status: "inativo",
            action: "Em baixa",
            observations: "Em baixa",
          }),
        }
      );
      // Refresh the equipment data after updating the status
      fetchEquipments(currentPage);
      toast.warning(`Equipamento ${equipmentId} inativado!`, {
        position: "bottom-right",
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Erro ao atualizar o status:", error);
      toast.error('Ops! Algo deu errado na inativação do equipamento.', {
        position: "bottom-right",
        autoClose: 3000,
      })
      // Handle the error appropriately (e.g., show an error message)
    }
  };

  const handleShowQRCode = (equipment) => {
    setSelectedEquipment(equipment);
    setShowQRCodeModal(true);
  };

  const handleEditEquipment = (equipment) => {
    setSelectedEquipment(equipment);
    setShowEditModal(true);
  };

  const handleDownloadQRCode = () => {
    const canvas = document.getElementById("qrCodeCanvas");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${selectedEquipment.equipmentId}_qrcode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handlePrintQRCode = () => {
    const qrCodeCanvas = document.getElementById("qrCodeCanvas");
    
    if (!qrCodeCanvas) {
      console.error("QR code canvas element not found");
      return;
    }
  
    const qrCodeDataURL = qrCodeCanvas.toDataURL();
    const printWindow = window.open("", "_blank");
  
    printWindow.document.write(
      `<img id="qrCodeImage" src="${qrCodeDataURL}" />`
    );
    printWindow.document.close();
  
    const qrCodeImage = printWindow.document.getElementById("qrCodeImage");
    qrCodeImage.onload = () => {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  
    qrCodeImage.onerror = () => {
      console.error("Failed to load QR code image");
      printWindow.close();
    };
  };

  const handleSaveEdit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const updatedData = Object.fromEntries(formData.entries());
    try {
      await fetch(
        `https://api.winglet.app/api/equipments/${selectedEquipment.equipmentId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            code: updatedData.code,
            type: updatedData.type,
            model: updatedData.model,
            manufacturer: updatedData.manufacturer,
            serialnumber: updatedData.serialnumber,
            companynumber: updatedData.companynumber,
            user: user.email,
          }),
        }
      );
      setShowEditModal(false);
      fetchEquipments(currentPage);
      toast.success(`Equipamento ${selectedEquipment.equipmentId} atualizado!`, {
        position: "bottom-right",
        autoClose: 3000,
      });
      // Refresh equipment data here
    } catch (error) {
      console.error("Error updating equipment:", error);
      toast.error(`Erro ao atualizar o equipamento ${selectedEquipment.equipmentId}`, {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };

  const handleProfilePictureClick = () => {};

  const handleProfilePictureChange = (event) => {
    setProfilePicture(URL.createObjectURL(event.target.files[0]));
  };

  const handleUpdatePassword = async (event) => {
    event.preventDefault();
    const userFormData = new FormData(event.target);
    const userData = Object.fromEntries(userFormData.entries());

    const toastId = toast.loading("Atualizando senha...");

    try {
      await updatePassword(
        user.email,
        userData.newPassword,
        userData.oldPassword
      );
      toast.update(toastId, {
        render: "Senha alterada com sucesso!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      if (error.message === "User not found") {
        toast.update(toastId, {
          render: "Erro ao atualizar a senha. Verifique a senha informada.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      } else if (error.message === "Authentication failed") {
        toast.update(toastId, {
          render: "Erro ao atualizar a senha: Senha antiga incorreta",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        toast.update(toastId, {
          render: "Erro ao atualizar a senha",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    } finally {
      setShowProfileModal(false);
    }
  };

  const renderCellContent = (equipment, key) => {
    switch (key) {
      case "equipmentId":
        let dotColor = "";
        if (
          equipment.currentStatus === "em uso" &&
          equipment.history &&
          equipment.history.length > 0
        ) {
          const lastUsed = new Date(
            equipment.history[equipment.history.length - 1].date
          );
          const now = new Date();
          const hoursInUse = (now - lastUsed) / (1000 * 60 * 60);
          if (hoursInUse >= 24) {
            dotColor = "red";
          } else if (hoursInUse >= 12) {
            dotColor = "orange";
          }
        }
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {dotColor && (
              <span
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: dotColor,
                  marginRight: "5px",
                  animation: "blink 1s infinite",
                }}
              />
            )}
            {equipment.equipmentId || "Equipamento não encontrado"}
          </div>
        );
      case "type":
        return (
          `${equipment.type} - ${equipment.manufacturer}` ||
          "Tipo não disponível"
        );
      case "status":
        return equipment.history && equipment.history.length > 0
          ? equipment.history[equipment.history.length - 1].status
          : equipment.currentStatus;
      case "date":
        return equipment.history && equipment.history.length > 0
          ? new Date(
              equipment.history[equipment.history.length - 1].date
            ).toLocaleString()
          : "-";
      case "lastUsed":
        if (
          equipment.history.length > 0 &&
          equipment.history[equipment.history.length - 1].nomeCompleto === "N/A"
        ) {
          return "-";
        } else if (
          equipment.history.length > 0 &&
          equipment.history[equipment.history.length - 1].nomeCompleto !== "N/A"
        ) {
          return new Date(
            equipment.history[equipment.history.length - 1].date
          ).toLocaleString();
        } else {
          return "-";
        }
      case "nomeCompleto":
        return equipment.history.length > 0 &&
          equipment.history[equipment.history.length - 1].nomeCompleto !== "N/A"
          ? `${equipment.history[equipment.history.length - 1].qrCode2} - ${
              equipment.history[equipment.history.length - 1].nomeCompleto
            } - ${equipment.history[equipment.history.length - 1].funcao}`
          : "-";
      case "repairStatus":
        // Implement logic to get repair status
        return "Not implemented";
      case "inUseTime":
        if (equipment.history && equipment.history.length > 0) {
          const lastDateEntry = new Date(
            equipment.history[equipment.history.length - 1].date
          );
          const currentDate = new Date();
          const diffInHours = Math.floor(
            (currentDate - lastDateEntry) / (1000 * 60 * 60)
          );
          return `${diffInHours.toString()} horas`;
        }
        return "-";
      case "changeStatus":
        return (
          <div className="d-flex justify-content-center">
            <Button
              variant="success"
              onClick={() => handleStatusChange(equipment.equipmentId)}
            >
              <a style={{ marginRight: "5px" }}>Disponibilizar</a>
              <FontAwesomeIcon icon={faCheckCircle} />
            </Button>
          </div>
        );
      case "actions":
        if (
          equipment.currentStatus === "inativo" ||
          equipment.currentStatus === "quebrado"
        ) {
          return (
            <div className="d-flex justify-content-center">
              <ButtonGroup>
                <DropdownButton
                  as={ButtonGroup}
                  title="Ações"
                  id="bg-nested-dropdown"
                >
                  <Dropdown.Item
                    eventKey="0"
                    onClick={() => handleShowQRCode(equipment)}
                  >
                    <FontAwesomeIcon icon={faQrcode} /> QR Code
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="3"
                    onClick={() => handleStatusChange(equipment.equipmentId)}
                  >
                    <FontAwesomeIcon icon={faCheck} color="green" />{" "}
                    Disponibilizar
                  </Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>
            </div>
          );
        } else if (equipment.currentStatus === "em uso") {
          return (
            <div className="d-flex justify-content-center">
              <ButtonGroup>
                <DropdownButton
                  as={ButtonGroup}
                  title="Ações"
                  id="bg-nested-dropdown"
                >
                  <Dropdown.Item
                    eventKey="0"
                    onClick={() => handleShowQRCode(equipment)}
                  >
                    <FontAwesomeIcon icon={faQrcode} /> QR Code
                  </Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>
            </div>
          );
        } else {
          return (
            <div className="d-flex justify-content-center">
              <ButtonGroup>
                <DropdownButton
                  as={ButtonGroup}
                  title="Ações"
                  id="bg-nested-dropdown"
                >
                  <Dropdown.Item
                    eventKey="0"
                    onClick={() => handleShowQRCode(equipment)}
                  >
                    <FontAwesomeIcon icon={faQrcode} /> QR Code
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="1"
                    onClick={() => handleEditEquipment(equipment)}
                  >
                    <FontAwesomeIcon icon={faEdit} /> Editar
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="2"
                    onClick={() => handleBaixa(equipment.equipmentId)}
                  >
                    <FontAwesomeIcon icon={faExclamationTriangle} color="red" />{" "}
                    Dar Baixa
                  </Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>
            </div>
          );
        }
      case "lastMaintenance":
        // Implement logic to get last maintenance date
        return "Not implemented";
      default:
        return "N/A";
    }
  };

  return (
    <div className="main-content" style={{ margin: "10px", display:"flex", flexDirection:"column", height:"100hv"}}>
      {/* Header */}
      <div className="row align-items-center mb-4">
        <div className="col-auto">
          <img
            src="https://grupoorbital.com.br/assets/img/logo-orbital.png"
            alt="Logo"
            className="logo"
            style={{ height: "50px" }}
          />
        </div>
        <div className="col text-center">
          <h1>EQUIPAMENTOS CEOP</h1>
        </div>
        <div className="col-auto">
          <Dropdown>
            <Dropdown.Toggle variant="link" id="dropdown-basic">
              <img
                src={
                  user.profile_picture ||
                  "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_640.png"
                } // Replace with actual image path or initials
                alt="User"
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                {" "}
                <Button
                  variant="link"
                  onClick={() => setShowProfileModal(true)}
                >
                  <FontAwesomeIcon icon={faUser} /> Perfil
                </Button>
              </Dropdown.Item>
              <Dropdown.Item>
                <Button variant="link" onClick={handleLogout}>
                  <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                </Button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <input
            type="file"
            id="profilePictureInput"
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleProfilePictureChange}
          />
        </div>
      </div>

      {/* Search Bar */}
      <div className="row mb-4">
        <div className="col">
          <Form.Control
            type="text"
            placeholder="Filtrar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {/* Tabs */}
      <Tabs
        justify={true}
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
        className="mb-4"
        style={{ color: "#1B447F" }}
      >
        <Tab eventKey="inUse" title={`Em uso`} />
        <Tab eventKey="available" title="Disponível" />
        <Tab eventKey="broken" title="Quebrados / Inativos" />
        <Tab eventKey="over12h" title="+12h" />
        <Tab eventKey="over24h" title="+24h" />
        <Tab eventKey="inventario" title={`Inventário (${totalItems})`}>
          <div className="d-flex justify-content-end mb-3">
            {user.isAdmin && user.see_inventory && (
              <>
                <Button
                  variant="success"
                  onClick={() => setShowAddEquipmentModal(true)}
                  className="me-2"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Adicionar
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setShowUploadModal(true)}
                  className="me-2"
                >
                  <FontAwesomeIcon icon={faFileArrowUp} className="me-2" />
                  Upload
                </Button>
              </>
            )}
            <Button variant="primary" onClick={exportToExcel}>
              <FontAwesomeIcon icon={faFileExcel} className="me-2" />
              Exportar
            </Button>
          </div>
        </Tab>
      </Tabs>

      {/* Table */}
      <div style={{ margin:"5px", height:"600px", overflowY: "scroll", flexGrow: "1" }}>
      <Table striped bordered hover size="sm" responsive>
        <thead>
          <tr>
            {tabColumns[activeTab].map((column) => (
              <th
                key={column.key}
                onClick={() => handleSort(column.key)}
                style={{ textAlign: "start", color: "#1B447F" }}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedEquipments.map((eq) => (
            <tr key={eq.equipmentId}>
              {tabColumns[activeTab].map((column) => (
                <td
                  key={column.key}
                  align="start"
                  style={{ alignContent: "center", textTransform: "uppercase" }}
                >
                  {renderCellContent(eq, column.key)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      </div>

      {/* Pagination */}
          <p style={{textAlign:"center", fontSize:"8px", marginBottom:"0px"}}>Versão 0.1.1 - Beta - Departamento de Inovação e Novas Tecnologias</p>
      <Modal show={showProfileModal} onHide={() => setShowProfileModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Perfil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleUpdatePassword}>
            <Form.Group controlId="name">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={`${user.name}`}
                disabled
              />
            </Form.Group>

            <Form.Group controlId="role">
              <Form.Label>Função</Form.Label>
              <Form.Control
                type="text"
                name="role"
                value={`${user.company_role}`}
                disabled
              />
            </Form.Group>

            <Form.Group controlId="oldPassword">
              <Form.Label>Senha Antiga</Form.Label>
              <Form.Control
                type="password"
                name="oldPassword"
                value={oldPassword}
                placeholder="Obrigatório"
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="newPassword">
              <Form.Label>Nova Senha</Form.Label>
              <Form.Control
                type="password"
                name="newPassword"
                value={newPassword}
                placeholder="Obrigatório"
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="confirmNewPassword">
              <Form.Label>Confirme a nova senha</Form.Label>
              <Form.Control
                type="password"
                name="confirmNewPassword"
                value={confirmNewPassword}
                placeholder="Obrigatório"
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowProfileModal(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="success"
                type="submit"
                disabled={
                  !oldPassword ||
                  !newPassword ||
                  !confirmNewPassword ||
                  confirmNewPassword !== newPassword
                }
              >
                Salvar
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Upload Modal */}
      <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Equipamentos em Lote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFile">
            <Form.Label>Selecione o arquivo</Form.Label>
            <a
              href="https://ceop.companyhub.app/content/modelo_importacao_ceop.xlsx"
              download
              className="d-block mb-2"
            >
              Fazer download do modelo de importação
            </a>
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUploadModal(false)}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleFileUpload}
            disabled={isUploading}
          >
            {isUploading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="sr-only"> Uploading...</span>
              </>
            ) : (
              "Adicionar"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showAddEquipmentModal}
        onHide={() => setShowAddEquipmentModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Equipamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddEquipment}>
            <Form.Group controlId="code">
              <Form.Label>Código do Equipamento</Form.Label>
              <Form.Control type="text" name="code" required />
            </Form.Group>

            <Form.Group controlId="type">
              <Form.Label>Tipo</Form.Label>
              <Form.Control as="select" name="type" required>
                <option value="">Selecione</option>
                <option value="RÁDIO COMUNICADOR">RÁDIO COMUNICADOR</option>
                <option value="CELULAR">CELULAR</option>
                <option value="SCANNER">SCANNER</option>
                <option value="CARREGADOR">CARREGADOR</option>
                <option value="LANTERNA">LANTERNA</option>
                <option value="ASPIRADOR">ASPIRADOR</option>
                <option value="BATERIA">BATERIA</option>
                <option value="BOLSA">BOLSA</option>
                <option value="BOLSA">CARTÃO</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="model">
              <Form.Label>Modelo</Form.Label>
              <Form.Control type="text" name="model" required />
            </Form.Group>

            <Form.Group controlId="manufacturer">
              <Form.Label>Fabricante</Form.Label>
              <Form.Control as="select" name="manufacturer" required>
                <option value="">Selecione</option>
                <option value="SEPURA">SEPURA</option>
                <option value="ENRICO">ENRICO</option>
                <option value="CALTTA">CALTTA</option>
                <option value="HYTERA">HYTERA</option>
                <option value="SAMSUNG">SAMSUNG</option>
                <option value="HONEYWELL">HONEYWELL</option>
                <option value="SIMBOL">SIMBOL</option>
                <option value="OUTRO">OUTRO</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="serialnumber">
              <Form.Label>Num. de Série</Form.Label>
              <Form.Control type="text" name="serialnumber" required />
            </Form.Group>

            <Form.Group controlId="companynumber">
              <Form.Label>Num. Patrimônio</Form.Label>
              <Form.Control type="text" name="companynumber" required />
            </Form.Group>

            <Button variant="success" type="submit">
              Adicionar
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showQRCodeModal} onHide={() => setShowQRCodeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedEquipment?.equipmentId} | {selectedEquipment?.type} -{" "}
            {selectedEquipment?.model}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <QRCodeCanvas
              id="qrCodeCanvas"
              value={selectedEquipment?.equipmentId || ""}
              size={256}
              marginSize={1}
            />
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <Button
              variant="primary"
              onClick={handleDownloadQRCode}
              className="mt-2"
              style={{ marginInline: "20px" }}
            >
              <FontAwesomeIcon icon={faFileDownload} className="me-2" />
              Baixar
            </Button>
            <Button variant="primary" onClick={handlePrintQRCode}>
              <FontAwesomeIcon icon={faPrint} className="me-2" />
              Imprimir
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Equipamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSaveEdit}>
            <Form.Group controlId="code">
              <Form.Label>Código</Form.Label>
              <Form.Control
                type="text"
                name="code"
                defaultValue={selectedEquipment?.equipmentId}
                required
              />
            </Form.Group>
            <Form.Group controlId="type">
              <Form.Label>Tipo</Form.Label>
              <Form.Control
                as="select"
                name="type"
                defaultValue={selectedEquipment?.type}
                required
              >
                <option value="">Selecione</option>
                <option value="RÁDIO COMUNICADOR">RÁDIO COMUNICADOR</option>
                <option value="CELULAR">CELULAR</option>
                <option value="SCANNER">SCANNER</option>
                <option value="CARREGADOR">CARREGADOR</option>
                <option value="LANTERNA">LANTERNA</option>
                <option value="ASPIRADOR">ASPIRADOR</option>
                <option value="BATERIA">BATERIA</option>
                <option value="BOLSA">BOLSA</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="model">
              <Form.Label>Modelo</Form.Label>
              <Form.Control
                type="text"
                name="model"
                defaultValue={selectedEquipment?.model}
                required
              />
            </Form.Group>
            <Form.Group controlId="manufacturer">
              <Form.Label>Fabricante</Form.Label>
              <Form.Control
                as="select"
                name="manufacturer"
                defaultValue={selectedEquipment?.manufacturer}
                required
              >
                <option value="">Selecione</option>
                <option value="SEPURA">SEPURA</option>
                <option value="ENRICO">ENRICO</option>
                <option value="CALTTA">CALTTA</option>
                <option value="HYTERA">HYTERA</option>
                <option value="SAMSUNG">SAMSUNG</option>
                <option value="HONEYWELL">HONEYWELL</option>
                <option value="SIMBOL">SIMBOL</option>
                <option value="OUTRO">OUTRO</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="serialnumber">
              <Form.Label>Num. de Série</Form.Label>
              <Form.Control
                type="text"
                name="serialnumber"
                defaultValue={selectedEquipment?.serialnumber}
                required
              />
            </Form.Group>
            <Form.Group controlId="companynumber">
              <Form.Label>Num. Patrimônio</Form.Label>
              <Form.Control
                type="text"
                name="companynumber"
                defaultValue={selectedEquipment?.numPatrimonio}
                required
              />
            </Form.Group>
            <div className="mt-3 d-flex justify-content-center">
              <Button variant="primary" type="submit">
                Salvar Edição
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ToastContainer limit={1} position="bottom-right" />
    </div>
  );
}

export default Dashboard;
